<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center">Filtro</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-datetime-picker
                                    label="Data"
                                    v-model="date"
                                    date-format="dd/MM/yyyy"
                                    clearText="Limpar"
                                    okText="Confirmar"
                                    :time-picker-props="timeProps"
                                    :textFieldProps="{ outlined: true }"
                                    reset-button
                                >
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Tipo de Transação"
                                    :items="possible_types_transaction"
                                    v-model="type_transaction"
                                    :item-text="getText"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                ></v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="ID do Jogador"
                                    v-model="player_id"
                                    data-vv-name="value"
                                    outlined
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="Valor"
                                    v-model="value"
                                    data-vv-name="value"
                                    outlined
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="ID do Agente"
                                    v-model="agent_id"
                                    data-vv-name="value"
                                    outlined
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    label="ID do Sub Agente"
                                    v-model="sub_agent_id"
                                    data-vv-name="value"
                                    outlined
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Gestor"
                                    :items="manager_types"
                                    v-model="manager_type"
                                    :item-text="getText"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                >
                                </v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-autocomplete
                                    label="Clube"
                                    :items="slots"
                                    v-model="slot"
                                    :error-messages="errors.collect('slots')"
                                    :item-text="getText"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                ></v-autocomplete>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Ligação"
                                    :items="possible_vinculations"
                                    v-model="vinculations"
                                    :item-text="getText"
                                    item-value="id"
                                    :clearable="true"
                                    clear-icon="mdi-close"
                                    outlined
                                >
                                </v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-select
                                    label="Tipos de Baixa"
                                    :items="lowTypes"
                                    v-model="selected_types_low"
                                    item-text="description"
                                    item-value="id"
                                    multiple
                                    small-chips
                                    outlined
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @mousedown.prevent
                                            @click="toggle"
                                        >
                                            <v-list-item-action>
                                                <v-icon
                                                    :color="
                                                        selected_types_low.length >
                                                        0
                                                            ? 'indigo darken-4'
                                                            : ''
                                                    "
                                                >
                                                    {{ icon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Selecionar Tudo
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-select>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-col>
                    <v-btn
                        text
                        @click="
                            setShowFilter({ show: false, type: 'trader' })
                            clearFilter()
                        "
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn text type="submit" right @click="filter"
                        >Filtrar</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
    name: 'PeriodConciliationTransactionsTraderFilter',
    created() {
        this.getSlots({ resetPage: false, noPages: true })
    },

    computed: {
        ...mapState('slots', ['slots']),
        ...mapState('period_conciliation_transactions', [
            'showDialog',
            'selectedItem',
        ]),
        ...mapState('period_conciliation_dialog_vinculation', ['lowTypes']),
        ...mapFields('period_conciliation_transactions', [
            'filter.date',
            'filter.player_id',
            'filter.agent_id',
            'filter.sub_agent_id',
            'filter.manager_type',
            'filter.value',
            'filter.slot',
            'filter.type_transaction',
            'filter.vinculations',
            'filter.selected_types_low',
        ]),
        selectedAllTypesLow() {
            return this.selected_types_low.length === this.lowTypes.length
        },
        selectedSomeTypeLow() {
            return (
                this.selected_types_low.length > 0 && !this.selectedAllTypesLow
            )
        },
        icon() {
            if (this.selectedAllTypesLow) return 'mdi-close-box'
            if (this.selectedSomeTypeLow) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        ...mapMutations('period_conciliation_transactions', [
            'setShowFilter',
            'clearFilter',
        ]),
        ...mapActions('period_conciliation_transactions', ['getItems']),
        ...mapActions('slots', ['getSlots']),

        getText(item) {
            return item.name
        },
        toggle() {
            this.$nextTick(() => {
                if (this.selectedAllTypesLow) {
                    this.selected_types_low = []
                } else {
                    this.selected_types_low = this.lowTypes.map(
                        (type) => type.id,
                    )
                }
            })
        },

        filter() {
            this.getItems({ resetPage: true, type: 1 })
            this.setShowFilter({ show: false, type: 'trader' })
        },
    },

    data: () => ({
        typeIndex: '',
        manager_types: [
            {
                id: 0,
                name: 'Interno',
            },
            {
                id: 1,
                name: 'Externo',
            },
            {
                id: 2,
                name: 'Sem gestor',
            },
        ],
        possible_vinculations: [
            {
                id: 0,
                name: 'Sem ligações',
            },
            {
                id: 1,
                name: 'Parcialmente ligado',
            },
            {
                id: 2,
                name: 'Completamente Ligado',
            },
        ],

        possible_types_transaction: [
            {
                id: 0,
                name: 'Retirada',
            },
            {
                id: 1,
                name: 'Envio',
            },
            {
                id: 2,
                name: 'Outros',
            },
        ],
        timeProps: {
            format: '24hr',
        },
    }),
}
</script>
